import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function HomePage({ title }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
            {/* <QlikObject
              className="page-title"
              id="ANJWBAj"
              height="35px"
              showLoader={false}
            /> */}
          </div>
          {/* <div className="col-md-2 col-sm-3 col-4">
            <QlikObject id="GqNcZ" height="35px" showLoader={false} />
          </div> */}
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject
              id="a247eac6-ac8e-41e5-bc7c-aca4c94026e8"
              height="30px"
              showLoader={false}
            />
          </div>
        </div>
      </div>
          
      <div className="row justify-content-start">
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="a49c567e-5174-4fbc-8463-a0e7a6a4ce20"
              height="30px"
              showLoader={false}
            />
          </div>
          <div className="col-xs-13 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="NYxkAp"
              height="40px"
              showLoader={false}
            />
          </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* income */}
            <QlikCard
              id="JPhjmJ"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
            {/* <QlikObject
              id="JPhjmJ"
              height="210px"
              showLoader={false}
              showHeader={false}
            /> */}
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* min sell value */}
            <QlikCard
              id="LYPcmTX"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* no of auctions */}
            <QlikCard
              id="EcJXFmb"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* KPI4 */}
            <QlikCard
              id="pGXWV"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* KPI5 */}
            <QlikCard
              id="rxjk"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* bids per auction */}
            <QlikCard
              id="zCav"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          
        </div>
        
        

       
        <div className="row justify-content-left">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <QlikCard
              id="SapGGmv"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
             
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <QlikCard
              id="CPmkTg"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
             
          </div>
          
        </div>
        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
            {/* vizCAVClassMain */}
            <QlikCard
              id="63168c64-e393-48dc-abcd-51dedbb3dc15"
              height="320px"
              showHeader={true}
              showMaximize={true}
              showExport={false}
            />
          </div>
          {/* vizOwnerMain */}
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <QlikCard
              id="gTZDmAE"
              height="320px"
              showHeader={true}
              showMaximize={true}
              showExport={false}
            />
          </div>
          </div>
          <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            {/* vizDynamiscMain */}
            <QlikCard
              id="f9533367-95fb-47a1-a8a2-90601edb3386"
              height="320px"
              showHeader={true}
              showMaximize={true}
              showExport={false}
            />
          </div>
        
        </div>

        <div className="row mb-2">
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject
              id="JMqrBrm"
              height="0px"
              showLoader={false}
            />
          </div>
        </div>
        
      </div>
    </section>
  );
}
