import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function Analysis({ title }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
            {/* <QlikObject
              className="page-title"
              id="RZeYHMr"
              height="35px"
              showLoader={false}
            /> */}
          </div>
          {/* <div className="col-md-2 col-sm-3 col-4">
            <QlikObject id="GqNcZ" height="35px" showLoader={false} />
          </div> */}
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="b0dda9e7-775a-4ad1-a5af-ba00a53b3e9b" height="35px" showLoader={false} />
          </div>
        </div>
      </div>

        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="a49c567e-5174-4fbc-8463-a0e7a6a4ce20"
              height="56px"
              showLoader={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="FfeW"
              height="45px"
              showLoader={false}
            />
          </div>
         
        </div>

      <div className="container-fluid">
        <div className="row justify-content-left">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizNoOfAuctionsKPI */}
            <QlikCard
              id="ef46d9cc-4800-4f5f-9d4c-55c05a3cbc3f"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizNoOfBiddersKPI */}
            <QlikCard
              id="7195b855-d71d-4f9d-baa2-89e41f0b7e2a"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizIncomeKPI */}
            <QlikCard
              id="59a482cf-9c0a-4a8a-a419-44f1c1690fa9"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizMinSellValueKPI */}
            <QlikCard
              id="f60507cb-a580-489c-b5f4-d7e2c85952e5"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizBidsPerAuctionKPI */}
            <QlikCard
              id="4416583b-656a-472c-9150-550c0ef025db"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizMSVIncreaseKPI */}
            <QlikCard
              id="01281ee4-f342-4a05-80c1-e0f671540656"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>


        <div className="row justify-content-left">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <QlikCard
              id="TkCpYVN"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <QlikCard
              id="bWgLr"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>
       
        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            {/* vizCustodianChart */}
            <QlikCard
              id="SbbCg"
              height="220px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
            {/* vizStageChart */}
            <QlikCard
              id="tPBMp"
              height="220px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            {/* vizEffectivenessChart */}
            <QlikCard
              id="80f8d2fd-36f1-457b-9bdd-20c91ee2f7a3"
              height="480px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
        </div>
        
       
      </div>
    </section>
  );
}
