import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function AssetsSSP({ title, menuItemsStrings }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
          </div>
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="bphrpQp" height="35px" showLoader={false} />
          </div>
        </div>
      </div>
      
      <div className="row justify-content-start">
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="a49c567e-5174-4fbc-8463-a0e7a6a4ce20"
              height="30px"
              showLoader={false}
            />
          </div>
          <div className="col-xs-13 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="FfeW"
              height="40px"
              showLoader={false}
            />
          </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizNoOfAuctionsKPI */}
            <QlikCard
              id="Rggphx"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizNoOfAuctionsKPI */}
            <QlikCard
              id="SsDwBgs"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizNoOfAuctionsKPI */}
            <QlikCard
              id="yBLYWAa"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizNoOfAuctionsKPI */}
            <QlikCard
              id="TcjnHB"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizNoOfAuctionsKPI */}
            <QlikCard
              id="UHkfau"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizNoOfAuctionsKPI */}
            <QlikCard
              id="qNeMMB"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          
        </div>

        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            {/* vizLastStatus */}
            <QlikCard
              id="MbkjMdJ"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            {/* vizHeadOrgan */}
            <QlikCard
              id="yXrJwX"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            {/* vizPrivatizationScale */}
            <QlikCard
              id="JrnkfT"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12">
            {/* vizTable */}
            <QlikCard
              id="MpKzQM"
              height="670px"
              showLoader={true}
              showExport={true}
              showHeader={true}
              exportText = {menuItemsStrings.exportText}
              exportCompletedText = {menuItemsStrings.exportCompletedText}
              closeBtnText = {menuItemsStrings.closeBtnText}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
