import { Switch, Route, Redirect } from "react-router-dom";
//import usePageViews from "hooks/usePageViews";

// Pages
import Home from "pages/Home";
import Auctions from "pages/Auctions";
import AnalysisSSP from "pages/AnalysisSSP";
import Analysis from "pages/Analysis";
import CompetitorAnalysis from "pages/CompetitorAnalysis";
import IndicatorDynamic from "pages/IndicatorDynamic";
import PeriodMonitoring from "pages/PeriodMonitoring";
import PeriodMonitoringSSP from "pages/PeriodMonitoringSSP";
import Participants from "pages/Participants";
import ParticipantsCard from "pages/ParticipantsCard";
import Contracts from "pages/Contracts";
import Questions from "pages/Questions";
import Sites from "pages/Sites";
import Organizers from "pages/Organizers";
import OrganizerCard from "pages/OrganizerCard";
import Geography from "pages/Geography";
import BestResults from "pages/BestResults";
import Selections from "pages/Selections";
import Assets from "pages/Assets";
import AssetItems from "pages/AssetItems";
import AssetsSSP from "pages/AssetsSSP";
import IncomeDynamics from "pages/IncomeDynamics";
import LeaseRegistry from "pages/LeaseRegistry";
import LeaseContracts from "pages/LeaseContracts";
import ProcedureType from "pages/ProcedureType";
import DirectionAnalysis from "pages/DirectionAnalysis";
import AnalyticTotal from "pages/AnalyticTotal";
import OrganizerResults from "pages/OrganizerResults";
import DisqualifiedBilling from "pages/DisqualifiedBilling";


export default function Routes({ menuLayout }) {
  //usePageViews();

  return (
    <Switch>
      <Route exact path="/">
        <Home title={menuLayout.MenuItem1} />
      </Route>
      <Route path="/auctions">
        <Auctions title={menuLayout.MenuItem2} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/analysisSSP">
        <AnalysisSSP title={menuLayout.MenuItem3} />
      </Route>
      <Route path="/analysis">
        <Analysis title={menuLayout.MenuItem4} />
      </Route>
      <Route path="/competitorAnalysis">
        <CompetitorAnalysis title={menuLayout.MenuItem5} />
      </Route>
      <Route path="/indicatorDynamic">
        <IndicatorDynamic title={menuLayout.MenuItem6} />
      </Route>
      <Route path="/periodMonitoring">
        <PeriodMonitoring title={menuLayout.MenuItem7} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/periodMonitoringSSP">
        <PeriodMonitoringSSP title={menuLayout.MenuItem8} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/participants">
        <Participants title={menuLayout.MenuItem9} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/participantsCard">
        <ParticipantsCard title={menuLayout.MenuItem10} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/contracts">
        <Contracts title={menuLayout.MenuItem11} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/questions">
        <Questions title={menuLayout.MenuItem12} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/sites">
        <Sites title={menuLayout.MenuItem13} />
      </Route>
      <Route path="/organizers">
        <Organizers title={menuLayout.MenuItem14} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/organizerCard">
        <OrganizerCard title={menuLayout.MenuItem15} />
      </Route>
      <Route path="/geography">
        <Geography title={menuLayout.MenuItem16} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/bestResults">
        <BestResults title={menuLayout.MenuItem17} />
      </Route>
      <Route path="/selections">
        <Selections title={menuLayout.MenuItem18} />
      </Route>
      <Route path="/assets">
        <Assets title={menuLayout.MenuItem19} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/assetItems">
        <AssetItems title={menuLayout.MenuItem20} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/assetsSSP">
        <AssetsSSP title={menuLayout.MenuItem21} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/incomeDynamics">
        <IncomeDynamics title={menuLayout.MenuItem22} />
      </Route>
      <Route path="/leaseRegistry">
        <LeaseRegistry title={menuLayout.MenuItem24} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/leaseContracts">
        <LeaseContracts title={menuLayout.MenuItem25} menuItemsStrings={menuLayout} />
      </Route>
      <Route path="/procedureType">
        <ProcedureType title={menuLayout.MenuItem28} />
      </Route>
      <Route path="/directionAnalysis">
        <DirectionAnalysis title={menuLayout.MenuItem29} />
      </Route>
      <Route path="/analyticTotal">
        <AnalyticTotal title={menuLayout.MenuItem31} />
      </Route>
      <Route path="/organizerResults">
        <OrganizerResults title={menuLayout.MenuItem33} />
      </Route>
      <Route path="/disqualifiedBilling">
        <DisqualifiedBilling title={menuLayout.MenuItem36} menuItemsStrings={menuLayout}/>
      </Route>
      <Redirect to="/" />
      
    </Switch>
  );
}
