import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function Geography({ title, menuItemsStrings }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
          </div>
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="618661ad-16b0-4d35-a464-510bcf5b3a8b" height="35px" showLoader={false} />
          </div>
        </div>
      </div>

      <div className="row justify-content-start">
          <div className="col-xs-13 col-sm-6 col-md-4 col-lg-3">
            <QlikObject
              id="a49c567e-5174-4fbc-8463-a0e7a6a4ce20"
              height="30px"
              showLoader={false}
            />
          </div>
          <div className="col-xs-13 col-sm-6 col-md-4 col-lg-3">
            <QlikObject
              id="NYxkAp"
              height="40px"
              showLoader={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            {/* vizIndicatorValue */}
            <QlikCard
              id="RNCjTK"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>

          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            {/* vizButton */}
            <QlikCard
              id="8b8a92ce-4fe9-403a-ab96-94ebca22d5a8"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* no of auction */}
            <QlikCard
              id="emSxwY"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* min sell value */}
            <QlikCard
              id="myxDB"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* income */}
            <QlikCard
              id="fZzuyT"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* KPI4 */}
            <QlikCard
              id="wFwmQ"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* KPI5 */}
            <QlikCard
              id="cNpRgBy"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* KPI6 */}
            <QlikCard
              id="GZCrtT"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          
        </div>
        </div>
      <div className="container-fluid">
       

        

        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
            <div className="row">
              <div className="col-12">
                {/* vizTable */}
                <QlikCard
                  id="Mmmbh"
                  height="410px"
                  showLoader={true}
                  showExport={true}
                  showHeader={true}
                  exportText = {menuItemsStrings.exportText}
                  exportCompletedText = {menuItemsStrings.exportCompletedText}
                  closeBtnText = {menuItemsStrings.closeBtnText}
                />
              </div>
            </div>
            
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            {/* vizMap */}
            <QlikCard
              id="bbf0e3b8-1cc2-4a35-916e-0d561d819a40"
              height="410px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
