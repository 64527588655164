import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function AnalitycTotal({ title }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
            
          </div>
          
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject
              id="mATakeG"
              height="30px"
              showLoader={false}
            />
          </div>
        </div>
      </div>

      <div className="container-fluid">
       
        <div className="row justify-content-left">
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
            <QlikCard
              id="AjveF"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
            <QlikCard
              id="PPueYK"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>
       
        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
            {/* vizCustodianChart */}
            <QlikCard
              id="RUfZgEv"
              height="300px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
            {/* vizStageChart */}
            <QlikCard
              id="zzbrmx"
              height="300px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            {/* vizEffectivenessChart */}
            <QlikCard
              id="UHuKx"
              height="600px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
        </div>

       
      </div>
    </section>
  );
}
