import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function Selections({ title }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
          </div>
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="LBudQg" height="35px" showLoader={false} />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            {/* vizPlannedAuctionDate */}
            <QlikCard
              id="ZunRHB"
              height="35px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            {/* vizAuctionDate */}
            <QlikCard
              id="WWHPEP"
              height="35px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
            {/* vizDate */}
            <QlikCard
              id="NHpczjz"
              height="35px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12">
            {/* vizFilterMain */}
            <QlikCard
              id="jmgfCd"
              height="675px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12">
            {/* vizText */}
            <QlikCard
              id="JEcpr"
              height="50px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
