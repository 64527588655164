import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function OrganizerCard({ title }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
            {/* <QlikObject
              className="page-title"
              id="RYcffGH"
              height="35px"
              showLoader={false}
            /> */}
          </div>
          {/* <div className="col-md-2 col-sm-3 col-4">
            <QlikObject id="GqNcZ" height="35px" showLoader={false} />
          </div> */}
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="VQVJNz" height="35px" showLoader={false} />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-left">
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            {/* vizFilters */}
            <QlikCard
              id="qJkgkk"
              height="350px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            {/* vizInfo */}
            <QlikCard
              id="YCbrjk"
              height="320px"
              showHeader={true}
              showMaximize={true}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
            {/* vizMetrics */}
            <QlikCard
              id="nGRPzy"
              height="320px"
              showHeader={true}
              showMaximize={true}
              showExport={false}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            {/* vizProcedureTypes */}
            <QlikCard
              id="mcLJp"
              height="320px"
              showHeader={true}
              showMaximize={true}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            {/* vizCAV */}
            <QlikCard
              id="APWdWU"
              height="320px"
              showHeader={true}
              showMaximize={true}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            {/* vizTenderers */}
            <QlikCard
              id="pEtstPZ"
              height="320px"
              showHeader={true}
              showMaximize={true}
              showExport={false}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
