import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loader from "components/Loader";
//import HomeFilters from "pages/Home/filters";

const YearMonthFilters = lazy(() =>
  import("pages/FiltersSidebar/YearMonthFilters")
);
const HomeFilters = lazy(() => import("pages/Home/filters"));
const AuctionsFilters = lazy(() => import("pages/Auctions/filters"));
const AnalysisSSPFilters = lazy(() => import("pages/AnalysisSSP/filters"));
const AnalysisFilters = lazy(() => import("pages/Analysis/filters"));
const CompetitorAnalysisFilters = lazy(() =>
  import("pages/CompetitorAnalysis/filters")
);
const IndicatorDynamicFilters = lazy(() =>
  import("pages/IndicatorDynamic/filters")
);
const PeriodMonitoringFilters = lazy(() =>
  import("pages/PeriodMonitoring/filters")
);
const PeriodMonitoringSSPFilters = lazy(() =>
  import("pages/PeriodMonitoringSSP/filters")
);
const ParticipantsFilters = lazy(() => import("pages/Participants/filters"));
const ParticipantsCardFilters = lazy(() =>
  import("pages/ParticipantsCard/filters")
);
const ContractsFilters = lazy(() => import("pages/Contracts/filters"));
const QuestionsFilters = lazy(() => import("pages/Questions/filters"));
const SitesFilters = lazy(() => import("pages/Sites/filters"));
const OrganizersFilters = lazy(() => import("pages/Organizers/filters"));
const OrganizerCardFilters = lazy(() => import("pages/OrganizerCard/filters"));
const GeographyFilters = lazy(() => import("pages/Geography/filters"));
const BestResultsFilters = lazy(() => import("pages/BestResults/filters"));
const IncomeDynamicsFilters = lazy(() =>
  import("pages/IncomeDynamics/filters")
);
const LeaseRegistryFilters = lazy(() => import("pages/LeaseRegistry/filters"));
const LeaseContractsFilters = lazy(() => import("pages/LeaseContracts/filters"));
const ProcedureTypeFilters = lazy(() => import("pages/ProcedureType/filters"));
const DirectionAnalysisFilters = lazy(() => import("pages/DirectionAnalysis/filters"));
const AnalyticTotalFilters = lazy(() => import("pages/AnalyticTotal/filters"));



const HomeFiltersPopups = lazy(() => import("pages/Home/filterspopups"));
const AuctionsFiltersPopups = lazy(() => import("pages/Auctions/filterspopups"));
const AnalysisSSPFiltersPopups = lazy(() => import("pages/AnalysisSSP/filterspopups"));
const AnalysisFiltersPopups = lazy(() => import("pages/Analysis/filterspopups"));
const CompetitorAnalysisFiltersPopups = lazy(() =>
  import("pages/CompetitorAnalysis/filterspopups")
);
const IndicatorDynamicFiltersPopups = lazy(() =>
  import("pages/IndicatorDynamic/filterspopups")
);
const PeriodMonitoringFiltersPopups = lazy(() =>
  import("pages/PeriodMonitoring/filterspopups")
);
const PeriodMonitoringSSPFiltersPopups = lazy(() =>
  import("pages/PeriodMonitoringSSP/filterspopups")
);
const ParticipantsFiltersPopups = lazy(() => import("pages/Participants/filterspopups"));
const ParticipantsCardFiltersPopups = lazy(() =>
  import("pages/ParticipantsCard/filterspopups")
);
const ContractsFiltersPopups = lazy(() => import("pages/Contracts/filterspopups"));
const QuestionsFiltersPopups = lazy(() => import("pages/Questions/filterspopups"));
const SitesFiltersPopups = lazy(() => import("pages/Sites/filterspopups"));
const OrganizersFiltersPopups = lazy(() => import("pages/Organizers/filterspopups"));
const OrganizerCardFiltersPopups = lazy(() => import("pages/OrganizerCard/filterspopups"));
const GeographyFiltersPopups = lazy(() => import("pages/Geography/filterspopups"));
const BestResultsFiltersPopups = lazy(() => import("pages/BestResults/filterspopups"));
const IncomeDynamicsFiltersPopups = lazy(() =>
  import("pages/IncomeDynamics/filterspopups")
);
const LeaseRegistryFiltersPopups = lazy(() => import("pages/LeaseRegistry/filterspopups"));
const LeaseContractsFiltersPopups = lazy(() => import("pages/LeaseContracts/filterspopups"));
const ProcedureTypeFiltersPopups = lazy(() => import("pages/ProcedureType/filterspopups"));
const DirectionAnalysisFiltersPopups = lazy(() => import("pages/DirectionAnalysis/filterspopups"));
const AnalyticTotalFiltersPopups = lazy(() => import("pages/AnalyticTotal/filterspopups"));

const AssetsFiltersPopups = lazy(() => import("pages/Assets/filterspopups"));
const AssetsSSPFiltersPopups = lazy(() => import("pages/AssetsSSP/filterspopups"));
const AssetItemsFiltersPopups = lazy(() => import("pages/AssetItems/filterspopups"));

const OrganizerResultsFilters = lazy(() => import("pages/OrganizerResults/filters"));
const OrganizerResultsFiltersPopups = lazy(() => import("pages/OrganizerResults/filterspopups"));

const DisqualifiedBillingFilters = lazy(() => import("pages/DisqualifiedBilling/filters"));
const DisqualifiedBillingFiltersPopups = lazy(() => import("pages/DisqualifiedBilling/filterspopups"));

export default function SideBar() {
  return (
    <Suspense fallback={<Loader />}>
      <div className="p-2">

      <Switch>
          <Route exact path="/">
            <HomeFiltersPopups />
          </Route>
          <Route path="/auctions">
            <AuctionsFiltersPopups />
          </Route>
          <Route path="/analyticTotal">
            <AnalyticTotalFiltersPopups />
          </Route>
          <Route path="/analysisSSP">
            <AnalysisSSPFiltersPopups />
          </Route>
          <Route path="/analysis">
            <AnalysisFiltersPopups />
          </Route>
          <Route path="/competitorAnalysis">
            <CompetitorAnalysisFiltersPopups />
          </Route>
          <Route path="/indicatorDynamic">
            <IndicatorDynamicFilters />
          </Route>
          <Route path="/periodMonitoring">
            <PeriodMonitoringFiltersPopups />
          </Route>
          <Route path="/periodMonitoringSSP">
            <PeriodMonitoringSSPFiltersPopups />
          </Route>
          <Route path="/participants">
            <ParticipantsFiltersPopups />
          </Route>
          <Route path="/participantsCard">
            <ParticipantsCardFiltersPopups />
          </Route>
          <Route path="/contracts">
            <ContractsFiltersPopups />
          </Route>
          <Route path="/questions">
            <QuestionsFiltersPopups />
          </Route>
          <Route path="/sites">
            <SitesFiltersPopups />
          </Route>
          <Route path="/organizers">
            <OrganizersFiltersPopups />
          </Route>
          <Route path="/organizerCard">
            <OrganizerCardFiltersPopups />
          </Route>
          <Route path="/geography">
            <GeographyFiltersPopups />
          </Route>
          <Route path="/bestResults">
            <BestResultsFiltersPopups />
          </Route>
          <Route path="/incomeDynamics">
            <IncomeDynamicsFiltersPopups />
          </Route>
          <Route path="/leaseRegistry">
            <LeaseRegistryFiltersPopups />
          </Route>
          <Route path="/leaseContracts">
            <LeaseContractsFiltersPopups />
          </Route>
          <Route path="/procedureType">
            <ProcedureTypeFiltersPopups />
          </Route>
          <Route path="/directionAnalysis">
            <DirectionAnalysisFiltersPopups />
          </Route>
          <Route path="/assets">
            <AssetsFiltersPopups />
          </Route>
          <Route path="/assetsSSP">
            <AssetsSSPFiltersPopups />
          </Route>
          <Route path="/assetItems">
            <AssetItemsFiltersPopups />
          </Route>
          <Route path="/organizerResults">
            <OrganizerResultsFiltersPopups />
          </Route>
          <Route path="/disqualifiedBilling">
            <DisqualifiedBillingFiltersPopups />
          </Route>
        </Switch>    

        <YearMonthFilters />
        
        <Switch>
          <Route exact path="/">
            <HomeFilters />
          </Route>
          <Route path="/auctions">
            <AuctionsFilters />
          </Route>
          <Route path="/analyticTotal">
            <AnalyticTotalFilters />
          </Route>
          <Route path="/analysisSSP">
            <AnalysisSSPFilters />
          </Route>
          <Route path="/analysis">
            <AnalysisFilters />
          </Route>
          <Route path="/competitorAnalysis">
            <CompetitorAnalysisFilters />
          </Route>
          <Route path="/indicatorDynamic">
            <IndicatorDynamicFiltersPopups />
          </Route>
          <Route path="/periodMonitoring">
            <PeriodMonitoringFilters />
          </Route>
          <Route path="/periodMonitoringSSP">
            <PeriodMonitoringSSPFilters />
          </Route>
          <Route path="/participants">
            <ParticipantsFilters />
          </Route>
          <Route path="/participantsCard">
            <ParticipantsCardFilters />
          </Route>
          <Route path="/contracts">
            <ContractsFilters />
          </Route>
          <Route path="/questions">
            <QuestionsFilters />
          </Route>
          <Route path="/sites">
            <SitesFilters />
          </Route>
          <Route path="/organizers">
            <OrganizersFilters />
          </Route>
          <Route path="/organizerCard">
            <OrganizerCardFilters />
          </Route>
          <Route path="/geography">
            <GeographyFilters />
          </Route>
          <Route path="/bestResults">
            <BestResultsFilters />
          </Route>
          <Route path="/incomeDynamics">
            <IncomeDynamicsFilters />
          </Route>
          <Route path="/leaseRegistry">
            <LeaseRegistryFilters />
          </Route>
          <Route path="/leaseContracts">
            <LeaseContractsFilters />
          </Route>
          <Route path="/procedureType">
            <ProcedureTypeFilters />
          </Route>
          <Route path="/directionAnalysis">
            <DirectionAnalysisFilters />
          </Route>
          <Route path="/organizerResults">
            <OrganizerResultsFilters />
          </Route>
          <Route path="/disqualifiedBilling">
            <DisqualifiedBillingFilters />
          </Route>
        </Switch>
      </div>
    </Suspense>
  );
}
