import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function DirectionAnalysis({ title }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
          </div>
          {/* <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="qHRNLp" height="35px" showLoader={false} />
          </div> */}
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="mVfSJu" height="35px" showLoader={false} />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6">
            <QlikCard
              id="RsmdgW"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-12 col-lg-2">
            <QlikCard
              id="DVpQUM"
              height="0px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-2">
            <QlikCard
              id="DVpQUM"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-2">
            <QlikCard
              id="QPsnZT"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12">
            {/* vizTable */}
            <QlikCard
              id="QPGCZJ"
              height="280px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>

          <div className="col-4">
            <div className="row justify-content-start">
              <div className="col-12">
                {/* vizTable */}
                <QlikCard
                  id="HyTmjUV"
                  height="56px"
                  showLoader={false}
                  showExport={false}
                  showHeader={false}
                />
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12">
                {/* vizTable */}
                <QlikCard
                  id="FJUsPJk"
                  height="320px"
                  showLoader={true}
                  showExport={false}
                  showHeader={true}
                />
              </div>
            </div>
          </div>
          <div className="col-8">
            {/* vizTable */}
            <QlikCard
              id="gNpes"
              height="380px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
