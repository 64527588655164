import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function AnalysisSSP({ title }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
            {/* <QlikObject
              className="page-title"
              id="GZyysum"
              height="35px"
              showLoader={false}
            /> */}
          </div>
          {/* <div className="col-md-2 col-sm-3 col-4">
            <QlikObject id="GqNcZ" height="35px" showLoader={false} />
          </div> */}
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="btHccU" height="35px" showLoader={false} />
          </div>
          
        </div>
      </div>

      <div className="row justify-content-start">
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="a49c567e-5174-4fbc-8463-a0e7a6a4ce20"
              height="30px"
              showLoader={false}
            />
          </div>
          <div className="col-xs-13 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="NYxkAp"
              height="40px"
              showLoader={false}
            />
          </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-left">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizNoOfAuctionsKPI */}
            <QlikCard
              id="ZmMPxnV"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizNoOfBiddersKPI */}
            <QlikCard
              id="sFxK"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizIncomeKPI */}
            <QlikCard
              id="jQnmXQj"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizMinSellValueKPI */}
            <QlikCard
              id="epM"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizBidsPerAuctionKPI */}
            <QlikCard
              id="pBmg"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2">
            {/* vizMSVIncreaseKPI */}
            <QlikCard
              id="skRwygM"
              height="210px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>

        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
            {/* vizIndicatorValue */}
            <QlikCard
              id="mXDZSvk"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          
          {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
            <QlikCard
              id="BfHvg"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div> */}
        </div>
        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
            {/* vizCustodianChart */}
            <QlikCard
              id="JAsjUak"
              height="210px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
            {/* vizStageChart */}
            <QlikCard
              id="mZWX"
              height="210px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7">
            {/* vizEffectivenessChart */}
            <QlikCard
              id="kYaSJdm"
              height="500px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            {/* vizEffectivenessChart */}
            <QlikCard
              id="EAPDQ"
              height="400px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
