import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function BestResults({ title }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
          </div>
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="PxkjvS" height="35px" showLoader={false} />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-center">
        <div className="col-xs-10 col-sm-6 col-md-5 col-lg-2">
            {/* vizMostExpensiveKPI */}
            <QlikCard
              id="HcZpwm"
              height="120px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-10 col-sm-6 col-md-5 col-lg-2">
            {/* vizMostExpensiveKPI */}
            <QlikCard
              id="bRQ"
              height="120px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-10 col-sm-6 col-md-5 col-lg-2">
            {/* vizMostExpensiveCompleteKPI */}
            <QlikCard
              id="WKdRBsx"
              height="120px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-10 col-sm-6 col-md-5 col-lg-2">
            {/* vizMostExpensiveDutchKPI */}
            <QlikCard
              id="FsgGyj"
              height="120px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-10 col-sm-6 col-md-5 col-lg-2">
            {/* vizBiggestMSVIncreaseKPI */}
            <QlikCard
              id="BvfBRFB"
              height="120px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>

        <div className="row justify-content-left">
          <div className="col-xs-10 col-sm-6 col-md-6 col-lg-6">
            {/* vizIndicatorValue */}
            <QlikCard
              id="3d6ec08a-ea93-4688-9bc1-d4f1360db1ee"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            {/* vizIndicatorValue */}
            <QlikCard
              id="TfpzVzQ"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
            {/* vizCAV */}
            <QlikCard
              id="ebqcfm"
              height="640px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
            {/* vizAuctions */}
            <QlikCard
              id="JsVds"
              height="320px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
             <QlikCard
              id="ZhbvK"
              height="320px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
        </div>


      </div>
    </section>
  );
}
