import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import ExpandableNavItem from "components/ExpandableNavItem";
import NavItem from "components/NavItem";

export default function MainMenu({ menuLayout, closeMenuHandler }) {
  let location = useLocation();
  useEffect(() => {
    closeMenuHandler && closeMenuHandler();
  }, [location, closeMenuHandler]);

  return (
    <>
      <li className="nav-item">
        <a
          className="nav-link jumpapp"
          href={"https://war-bi." + process.env.REACT_APP_QLIK_LINK_ADDRESS + "/"}
          target="_blank"
          rel="noreferrer"
        >
          <i className="lui-icon  lui-icon--log-in"></i>
          <p>{menuLayout.MenuItem27}</p>
        </a>
      </li>
      <NavItem
        to="/"
        text={menuLayout.MenuItem1}
        icon={<FontAwesomeIcon icon="home" />}
        //icon="nav-icon fas fa-home"
      />
      <NavItem
        to="/auctions"
        text={menuLayout.MenuItem2}
        icon={<FontAwesomeIcon icon="money-check" />} //"nav-icon fas fa-money-check"
      />
      <NavItem
        to="/procedureType"
        text={menuLayout.MenuItem28}
        icon="nav-icon lui-icon lui-icon--book"
      />
      <NavItem
        to="/directionAnalysis"
        text={menuLayout.MenuItem29}
        icon="nav-icon lui-icon lui-icon--distributionplot"
      />
      <NavItem
        to="/analysisSSP"
        text={menuLayout.MenuItem3}
        //icon="nav-icon fas fa-industry"
        icon={<FontAwesomeIcon icon="industry" />}
      />
      <NavItem
        to="/analysis"
        text={menuLayout.MenuItem4}
        // icon="nav-icon fas fa-check-square"
        icon={<FontAwesomeIcon icon="check-square" />}
      />
      <NavItem
        to="/competitorAnalysis"
        text={menuLayout.MenuItem5}
        // icon="nav-icon fas fa-hands-helping"
        icon={<FontAwesomeIcon icon="hands-helping" />}
      />
      <NavItem
        to="/indicatorDynamic"
        text={menuLayout.MenuItem6}
        icon="nav-icon lui-icon lui-icon--combo-chart"
      />
      <NavItem
        to="/analyticTotal"
        text={menuLayout.MenuItem31}
        // icon="nav-icon lui-icon lui-icon--shapes"
       icon="nav-icon lui-icon lui-icon--shapes" 
      />
      <NavItem
        to="/periodMonitoring"
        text={menuLayout.MenuItem7}
        // icon="nav-icon fas fa-calendar"
        icon={<FontAwesomeIcon icon="calendar" />}
      />
      <NavItem
        to="/periodMonitoringSSP"
        text={menuLayout.MenuItem8}
        // icon="nav-icon fas fa-calendar-minus"
        icon={<FontAwesomeIcon icon="calendar-minus" />}
      />
      <NavItem
        to="/participants"
        text={menuLayout.MenuItem9}
        // icon="nav-icon fas fa-users"
        icon={<FontAwesomeIcon icon="users" />}
      />
      <NavItem
        to="/participantsCard"
        text={menuLayout.MenuItem10}
        // icon="nav-icon fas fa-address-card"
        icon={<FontAwesomeIcon icon="address-card" />}
      />
      <NavItem
        to="/contracts"
        text={menuLayout.MenuItem11}
        // icon="nav-icon fas fa-money-check-alt"
        icon={<FontAwesomeIcon icon="money-check-alt" />}
      />
      <NavItem
        to="/questions"
        text={menuLayout.MenuItem12}
        // icon="nav-icon fas fa-comments"
        icon={<FontAwesomeIcon icon="comments" />}
      />
      <NavItem
        to="/sites"
        text={menuLayout.MenuItem13}
        // icon="nav-icon fas fa-desktop"
        icon={<FontAwesomeIcon icon="desktop" />}
      />
      <NavItem
        to="/organizers"
        text={menuLayout.MenuItem14}
        // icon="nav-icon fas fa-user-tie"
        icon={<FontAwesomeIcon icon="user-tie" />}
      />
      <NavItem
        to="/organizerCard"
        text={menuLayout.MenuItem15}
        // icon="nav-icon fas fa-id-card"
        icon={<FontAwesomeIcon icon="id-card" />}
      />
      <NavItem
        to="/organizerResults"
        text={menuLayout.MenuItem33}
        icon="nav-icon lui-icon lui-icon--descending"
        // icon={<FontAwesomeIcon icon="user-circle" />}
      />
      <NavItem
        to="/disqualifiedBilling"
        text={menuLayout.MenuItem36}
        icon={<FontAwesomeIcon icon="scale-unbalanced" />}
      />
      <NavItem
        to="/geography"
        text={menuLayout.MenuItem16}
        icon="nav-icon lui-icon lui-icon--map"
      />
      <NavItem
        to="/bestResults"
        text={menuLayout.MenuItem17}
        // icon="nav-icon fas fa-award"
        icon={<FontAwesomeIcon icon="award" />}
      />
      <NavItem
        to="/selections"
        text={menuLayout.MenuItem18}
        // icon="nav-icon fas fa-search"
        icon={<FontAwesomeIcon icon="search" />}
      />
      <NavItem
        to="/assets"
        text={menuLayout.MenuItem19}
        // icon="nav-icon fas fa-warehouse"
        icon={<FontAwesomeIcon icon="warehouse" />}
      />
      <NavItem
        to="/assetItems"
        text={menuLayout.MenuItem20}
        // icon="nav-icon fas fa-box-open"
        icon={<FontAwesomeIcon icon="box-open" />}
      />
      <NavItem
        to="/assetsSSP"
        text={menuLayout.MenuItem21}
        // icon="nav-icon fas fa-university"
        icon={<FontAwesomeIcon icon="university" />}
      />
      <NavItem
        to="/incomeDynamics"
        text={menuLayout.MenuItem22}
        icon="nav-icon lui-icon lui-icon--line-chart"
      />
      <NavItem
        to="/leaseRegistry"
        text={menuLayout.MenuItem24}
        // icon="nav-icon fas fa-table"
        icon={<FontAwesomeIcon icon="table" />}
      />
      <NavItem
        to="/leaseContracts"
        text={menuLayout.MenuItem25}
        // icon="nav-icon fas fa-table"
        icon={<FontAwesomeIcon icon="table" />}
      />
      <li className="nav-item">
        <a
          className="nav-link jumpapp"
          href={"https://lease-bi." + process.env.REACT_APP_QLIK_LINK_ADDRESS + "/"}
          target="_blank"
          rel="noreferrer"
        >
          <i className="lui-icon  lui-icon--log-in"></i>
          <p>{menuLayout.MenuItem32}</p>
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link jumpapp"
          href={"https://land-bi." + process.env.REACT_APP_QLIK_LINK_ADDRESS + "/"}
          target="_blank"
          rel="noreferrer"
        >
          <i className="lui-icon  lui-icon--log-in"></i>
          <p>{menuLayout.MenuItem26}</p>
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link jumpapp"
          href={"https://privatization-bi." + process.env.REACT_APP_QLIK_LINK_ADDRESS + "/"}
          target="_blank"
          rel="noreferrer"
        >
          <i className="lui-icon  lui-icon--log-in"></i>
          <p>{menuLayout.MenuItem34}</p>
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link jumpapp"
          href={"https://nps-bi." + process.env.REACT_APP_QLIK_LINK_ADDRESS + "/"}
          target="_blank"
          rel="noreferrer"
        >
          <i className="lui-icon  lui-icon--log-in"></i>
          <p>{menuLayout.MenuItem35}</p>
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link jumpapp"
          href={"https://timber." + process.env.REACT_APP_QLIK_LINK_ADDRESS + "/"}
          target="_blank"
          rel="noreferrer"
        >
          <i className="lui-icon  lui-icon--log-in"></i>
          <p>{menuLayout.MenuItem23}</p>
        </a>
      </li>

      {/* <ExpandableNavItem
        text="Dashboard"
        icon="nav-icon lui-icon lui-icon--home"
      >
        <NavItem
          to="/"
          text="Dashboard"
          icon="nav-icon lui-icon lui-icon--shapes"
        />
        <NavItem
          to="/page2"
          text="Dashboard v2"
          icon="nav-icon lui-icon lui-icon--library"
        />
      </ExpandableNavItem> */}

      {/* <ExpandableNavItem
        text="Analysis"
        icon="nav-icon lui-icon lui-icon--view"
      >
        <NavItem
          to="/page3"
          text="Page 3"
          icon="lui-icon lui-icon--map nav-icon"
        />
        <NavItem
          to="/page4"
          text="Page 4"
          icon="lui-icon lui-icon--direct-discovery nav-icon"
        />        
      </ExpandableNavItem> */}
    </>
  );
}
