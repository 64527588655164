import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function IndicatorDynamic({ title }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
          </div>
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="6ffeb4a6-951a-4514-8d9e-9cbd95f50c56" height="35px" showLoader={false} />
          </div>
          
        </div>
      </div>
      <div className="row justify-content-start">
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="mBbJj"
              height="30px"
              showLoader={false}
            />
          </div>
         
      </div>

      <div className="container-fluid">
      <div className="row justify-content-center mt20">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            {/* vizDynamiscMain */}
            <QlikCard
              id="kuKtjB"
              height="60px"
              showHeader={true}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
            {/* vizDynamiscMain */}
            <QlikCard
              id="GeXnUeM"
              height="60px"
              showHeader={true}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            {/* vizDynamiscMain */}
            <QlikCard
              id="kSDPKMV"
              height="585px"
              showHeader={true}
              showMaximize={true}
              showExport={false}
            />
          </div>
        </div>
      </div>

{/*
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <QlikCard
              id="maRstG"
              height="320px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <QlikCard
              id="umLmE"
              height="320px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <QlikCard
              id="qGNZhd"
              height="320px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <QlikCard
              id="hwWjYm"
              height="320px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
        </div>
      </div>
  */}
    </section>
  );
}
