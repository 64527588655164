import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function IncomeDynamics({ title }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
          </div>
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="XLeVTVj" height="35px" showLoader={false} />
          </div>
        </div>
      </div>
      <div className="row justify-content-start">
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="a49c567e-5174-4fbc-8463-a0e7a6a4ce20"
              height="30px"
              showLoader={false}
            />
          </div>
          <div className="col-xs-13 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="FfeW"
              height="40px"
              showLoader={false}
            />
          </div>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            {/* vizNoOfAuctionsKPI */}
            <QlikCard
              id="077c0257-17b6-45af-9cc9-04c506d296b2"
              height="150px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            {/* vizIncomeKPI */}
            <QlikCard
              id="0ad6efc5-ee17-4c8b-af2a-b19c9c25866d"
              height="150px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            {/* vizMSVKPI */}
            <QlikCard
              id="6c12f248-8b30-4409-a0f6-3c5866da2999"
              height="150px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>

        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            {/* vizYear */}
            <QlikCard
              id="rJYs"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          {/* <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            <QlikCard
              id="spZHwz"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            <QlikCard
              id="PXxhbHj"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div> */}
        </div>

        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            {/* vizSwitch */}
            <QlikCard
              id="TwPpsv"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            {/* vizSwitch2 */}
            <QlikCard
              id="nDPng"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
            {/* vizSwitch2 */}
            <QlikCard
              id="yKuSL"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          {/* <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            <QlikCard
              id="dCgJv"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            <QlikCard
              id="spZHwz"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div> */}
        </div>

        {/* <div className="row justify-content-left">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <QlikCard
              id="yKuSL"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div> */}

        <div className="row justify-content-center">
          <div className="col-12">
            {/* vizTable */}
            <QlikCard
              id="krJMYB"
              height="280px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            {/* vizChart */}
            <QlikCard
              id="kPtnfm"
              height="320px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
