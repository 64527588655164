import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function ParticipantsCard({ title, menuItemsStrings }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
            {/* <QlikObject
              className="page-title"
              id="GfjLjG"
              height="35px"
              showLoader={false}
            /> */}
          </div>
          {/* <div className="col-md-2 col-sm-3 col-4">
            <QlikObject id="GqNcZ" height="35px" showLoader={false} />
          </div> */}
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="aed2d480-ed91-4a25-8095-d351e56a5892" height="35px" showLoader={false} />
          </div>
        </div>
      </div>
          
      <div className="row justify-content-start">
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            <QlikObject
              id="eKvuwJ"
              height="40px"
              showLoader={false}
            />
          </div>

      </div>

      <div className="container-fluid">
        <div className="row justify-content-left">
          
          <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            {/* vizInfo */}
            <QlikCard
              id="3d5f6835-9915-420f-9255-5204b0d5562f"
              height="310px"
              showHeader={true}
              showMaximize={true}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-9 col-lg-9">
            {/* vizTable */}
            <QlikCard
              id="83771584-877f-4bba-91f1-6b2fcd581488"
              height="310px"
              showHeader={true}
              showMaximize={true}
              showExport={true}
              exportText = {menuItemsStrings.exportText}
              exportCompletedText = {menuItemsStrings.exportCompletedText}
              closeBtnText = {menuItemsStrings.closeBtnText}
            />
          </div>
        </div>

        <div className="row justify-content-center">
        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            {/* vizTable */}
            <QlikCard
              id="mmgLHJ"
              height="310px"
              showHeader={true}
              showMaximize={true}
              showExport={true}
              exportText = {menuItemsStrings.exportText}
              exportCompletedText = {menuItemsStrings.exportCompletedText}
              closeBtnText = {menuItemsStrings.closeBtnText}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            {/* vizTable */}
            <QlikCard
              id="pqDau"
              height="310px"
              showHeader={true}
              showMaximize={true}
              showExport={true}
              exportText = {menuItemsStrings.exportText}
              exportCompletedText = {menuItemsStrings.exportCompletedText}
              closeBtnText = {menuItemsStrings.closeBtnText}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
            {/* vizTable */}
            <QlikCard
              id="0caf9d05-9f84-4a66-ad47-1ecd78eab849"
              height="310px"
              showHeader={true}
              showMaximize={true}
              showExport={true}
              exportText = {menuItemsStrings.exportText}
              exportCompletedText = {menuItemsStrings.exportCompletedText}
              closeBtnText = {menuItemsStrings.closeBtnText}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
