import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function OrganizerResults({ title }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
          </div>
          {/* <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="qHRNLp" height="35px" showLoader={false} />
          </div> */}
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="PwJjRs" height="35px" showLoader={false} />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            <QlikCard
              id="zDEawVk"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            <QlikCard
              id="WtjPyL"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            <QlikCard
              id="jnJMbLC"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            <QlikCard
              id="wENJGvE"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>

        <div className="row justify-content-start">
          <div className="col-12">
            <QlikCard
              id="qYjwW"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>

        <div className="row justify-content-center">

          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="row justify-content-start">
              <div className="col-12">
                {/* statistics table */}
                <QlikCard
                  id="aPFRyUp"
                  height="200px"
                  showLoader={true}
                  showExport={true}
                  showHeader={true}
                />
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-sm-12 col-md-12 col-lg-6">
                {/* pie chart */}
                <QlikCard
                  id="NWSFw"
                  height="380px"
                  showLoader={true}
                  showExport={true}
                  showHeader={true}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                {/* bar chart */}
                <QlikCard
                  id="paakeVK"
                  height="380px"
                  showLoader={true}
                  showExport={true}
                  showHeader={true}
                />
              </div>
            </div>
          </div>
          
          <div className="col-sm-12 col-md-12 col-lg-6">
          <div className="row justify-content-start">
              <div className="col-12">
                {/* top 3 auctions */}
                <QlikCard
                  id="uJGdy"
                  height="280px"
                  showLoader={true}
                  showExport={true}
                  showHeader={true}
                />
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-12">
                {/* organizer comparison */}
                <QlikCard
                  id="BLsBLn"
                  height="220px"
                  showLoader={true}
                  showExport={true}
                  showHeader={true}
                />
              </div>
            </div>
            <div className="row justify-content-start">
              <div className="col-sm-12 col-md-12 col-lg-6">
                {/* organizer comparison */}
                <QlikCard
                  id="CJUBetB"
                  height="56px"
                  showLoader={false}
                  showExport={false}
                  showHeader={false}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}
