import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function Organizers({ title, menuItemsStrings }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
            {/* <QlikObject
              className="page-title"
              id="gxkqpBZ"
              height="35px"
              showLoader={false}
            /> */}
          </div>
          {/* <div className="col-md-2 col-sm-3 col-4">
            <QlikObject id="GqNcZ" height="35px" showLoader={false} />
          </div> */}
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="ujXFmm" height="35px" showLoader={false} />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        {/* <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <QlikCard
              id="ac000f3f-1f2e-4dfc-8c6c-d3c2c75968bc"
              height="65px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
            <QlikCard
              id="a25ab9b7-6ab1-487a-8d9e-a456520f61f8"
              height="65px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>
        <div className="row justify-content-start">
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3">
            <QlikCard
              id="dtJQbVq"
              height="35px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-3">
            <QlikCard
              id="rqpRFv"
              height="35px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div> */}

        <div className="row justify-content-center">
          <div className="col-12">
            {/* vizTable */}
            <QlikCard
              id="3b35be04-d5d7-4626-9089-d88eb0a81630"
              height="670px"
              showLoader={true}
              showExport={true}
              showHeader={true}
              exportText = {menuItemsStrings.exportText}
              exportCompletedText = {menuItemsStrings.exportCompletedText}
              closeBtnText = {menuItemsStrings.closeBtnText}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
