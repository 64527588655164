import QlikCard from "components/QlikCard";
import QlikObject from "components/QlikObject";

export default function Sites({ title }) {
  return (
    <section className="content">
      <div className="content-header">
        <div className="row mb-2">
          <div className="col-md-10 col-sm-9 col-10">
            <h5 className="page-title"> {title} </h5>
            {/* <QlikObject
              className="page-title"
              id="uELRK"
              height="35px"
              showLoader={false}
            /> */}
          </div>
          {/* <div className="col-md-2 col-sm-3 col-4">
            <QlikObject id="GqNcZ" height="35px" showLoader={false} />
          </div> */}
          <div className="col-md-2 col-sm-3 col-2">
            <QlikObject id="e2c2c7af-de37-41fb-bd93-028069c8e2e4" height="35px" showLoader={false} />
          </div>
        </div>
      </div>

      <div className="container-fluid">
    
        <div className="row justify-content-start">
         
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <QlikCard
              id="0001c5f4-2448-464c-9dc5-3c35bb5627ee"
              height="56px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <QlikCard
              id="f5dd79b5-0a97-4596-aa24-368c633a6ce3"
              height="40px"
              showHeader={false}
              showMaximize={false}
              showExport={false}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
            {/* vizGeneral */}
            <QlikCard
              id="fkCRcS"
              height="320px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
        
        </div>
        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="row justify-content-center">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-12">
                {/* vizPie */}
                <QlikCard
                  id="4bdcb1af-efb7-4941-9eae-c262b236ecdd"
                  height="320px"
                  showLoader={true}
                  showExport={false}
                  showHeader={true}
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <QlikCard
                  id="jmgSV"
                  height="56px"
                  showHeader={false}
                  showMaximize={false}
                  showExport={false}
                  />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <QlikCard
                  id="HSpxS"
                  height="56px"
                  showHeader={false}
                  showMaximize={false}
                  showExport={false}
                  />
              </div>

            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            {/* vizMSVIncrease */}
            <QlikCard
              id="vNLmZ"
              height="370px"
              showLoader={true}
              showExport={false}
              showHeader={true}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
